import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        const tabsList = ["Main skills","Experience","Education & Certification"]
        const { tabStyle } = this.props
        const mainSkills = [ 
            {name:"Web design",type:"5 years",description:"Excellent understanding of HTML and CSS."},
            {name:"API Development",type:"4 years",description:"Proficient in building solid backends with languages like JavaScript, Python, and PHP."},
            {name:"Version Control",type:"4 years",description:"It plays a vital role in enabling teamwork, keeping our code organized and easily manageable, and ensuring we can effectively track and incorporate changes."},
            {name:"Cloud Computing",type:"2 year",description:"I have a solid understanding of cloud computing, with extensive knowledge in areas such as cloud architecture, infrastructure, and services."},
            {name:"Agile",type:"3 year",description:"I'm expirenced in using Agile methodology and working in Agile environments."}
        ]
        const Experiences = [ 
            {title: "Software Engineer II",company:"OMD",startDate:"2019",EndDate:"Current"},
            {title: "Web Developer",company:"Freelance",startDate:"2018",EndDate:"Current"},
            {title: "IT Developer",company:"Patria Rivas Lab",startDate:"2016",EndDate:"2018"},
        ]
        const Education = [ 
            {title: "Computer Science",place:"Universidad Dominico-Americano (UNICDA)",startDate:"2016"},
            {title: "Software Development",place:"Instituto Tecnologico de las Americas (ITLA)",startDate:"2013"},
            {title: "Technical bachelor's degree",place:"Instituto Politecnico de Azua (IPA)",startDate:"2011"},
        ]
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        {tabsList.map( (tab,index)=> <Tab key={`tab_${tab}_${index}`}>{tab}</Tab>)}
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                { mainSkills.map( ({name,type,description},index) => (<li key={`main_skill_${index}`}> <a href="/service">{name} <span> - {type}</span></a> {description}</li>))}
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                            { Experiences.map( ({title,company,startDate,EndDate},index) => (<li key={`experiences_${index}`}> <a href="/service">{title}<span> - {company}</span></a> {startDate} - {EndDate}</li>))}
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                            { Education.map( ({title,place,startDate},index) => (<li key={`education_${index}`}> <a href="/service">{title}<span> - {place}</span></a> {startDate} </li>))}
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;