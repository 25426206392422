import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: "https://assets.servioquezada.com/servioquezada.com/bsc/homepage_desktop.png",
        category: 'Web Site',
        title: 'Banco Santa Cruz',
        url: "https://bsc.com.do/"
    },
    {
        image: "https://assets.servioquezada.com/servioquezada.com/bludigital/homepge_desktop.png",
        category: 'Web Site',
        title: 'Bludigital',
        url: "https://bludigital.do/"
    }
] 

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{backgroundImage: `url(${value?.image})`, backgroundSize: "contain",backgroundRepeat: "no-repeat",opacity: 0.6}}></div>
                                {/* <div className={`bg-blr-image`} style={{backgroundImage: `url(${value?.image})`, backgroundSize: "contain",backgroundRepeat: "no-repeat"}}></div> */}
                            </div>
                            <div className="content">
                                <div className="inner">
                                    {/* <p>{value.category}</p> */}
                                    <h4><a href={`${value.url}`} target="_blank" rel="noopener noreferrer">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.url}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </div>
                                </div>
                            </div>
                            <a className="link-overlay" href={`${value.url}`} target="_blank" rel="noopener noreferrer">View more</a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;